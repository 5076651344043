import {Button} from '@mui/material';
import React, {useLayoutEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Android, ArrowRight} from '@mui/icons-material';
import driverimage from '../../Assets/driverimage.jpg';
import styled, {keyframes, css} from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform : translateY(3em);
    opacity: 0;
  }
  50% {
    transform : translateY(3em);
    opacity: 0;
  }
  100% {
    transform : translateY(0em);
    opacity: 1;
  }
`;

const fadeInX = keyframes`
  0% {
    transform : translateX(-10em);
    opacity: 0;
  }
  50% {
    transform : translateX(-10em);
    opacity: 0;
  }
  100% {
    transform : translateX(0em);
    opacity: 1;
  }
`;

const EnterDiv = styled.div`
  animation-fill-mode: forwards;
  visibility: ${props => (props.animateDrivers ? 'visible' : 'hidden')};
  animation: ${props =>
    props.animateDrivers &&
    css`
      ${fadeIn} 1.0s linear 1
    `};
`;

const EnterXDiv = styled.div`
  animation-fill-mode: forwards;
  visibility: ${props => (props.animateDrivers ? 'visible' : 'hidden')};
  animation: ${props =>
    props.animateDrivers &&
    css`
      ${fadeInX} 1.0s linear 1
    `};
`;

// eslint-disable-next-line react/prop-types
const Cadaster = ({animateDrivers}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();

  return (
    <div
      style={{
        width: width,
        height: 'auto',
        background: 'linear-gradient(#F1F1F1, #F6F6F6, #FFFFFF)',
      }}
    >
      {isVerySmall || isSmall ? (
        <div
          style={{
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
            backgroundImage: `url(${driverimage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundImage: driverimage,
              marginBottom: 60,
            }}
          >
            <p
              style={{
                fontSize: 36,
                width: '90%',
                marginLeft: 30,
                marginTop: 20,
                fontWeight: 'bold',
                color: '#FFFFFF',
                marginBottom: 10,
              }}
            >
              Faça viagens e entregas pela Irê it.
            </p>
            <p
              style={{
                fontSize: 20,
                width: '90%',
                marginLeft: '2.5%',
                marginTop: 10,
                fontWeight: 'bold',
                color: '#FFFFFF',
                background: 'rgba(0, 0, 0, 0.5)',
                padding: 10,
                borderRadius: 5,
              }}
            >
              A Irê it encontra as melhores viagens para você com uma taxa
              justa. Conheça nosso app para motoristas, entregadores e
              mototaxistas
            </p>
            <div
              style={{display: 'flex', marginTop: 20, flexDirection: 'column'}}
            >
              <Button
                style={{
                  textTransform: 'none',
                  width: 'auto',
                  marginLeft: 20,
                  marginRight: 20,
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                }}
                sx={{
                  color: '#06448E',
                  bgcolor: '#FFFFFF',
                  ':hover': {
                    bgcolor: '#06448E',
                    color: '#FFFFFF',
                  },
                }}
              >
                <Android />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 20,
                    }}
                  >
                    Play Store
                  </p>
                </div>
              </Button>
              <Button
                style={{
                  textTransform: 'none',
                  width: 'auto',
                  marginLeft: 20,
                  marginRight: 20,
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginTop: 20,
                }}
                sx={{
                  color: '#06448E',
                  bgcolor: '#FFFFFF',
                  ':hover': {
                    bgcolor: '#06448E',
                    color: '#FFFFFF',
                  },
                }}
              >
                <div style={{marginRight: 20}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Mais
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 20,
                    }}
                  >
                    Informações
                  </p>
                </div>
                <ArrowRight />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
            backgroundImage: `url(${driverimage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingBottom: 60,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundImage: driverimage,
            }}
          >
            <p
              style={{
                fontSize: 48,
                width: '50%',
                marginLeft: 30,
                marginTop: 60,
                fontWeight: 'bold',
                color: '#FFFFFF',
              }}
            >
              Faça viagens e entregas pela Irê it.
            </p>

            <EnterXDiv animateDrivers={animateDrivers}>
              <p
                style={{
                  fontSize: 28,
                  width: '40%',
                  marginLeft: 30,
                  marginTop: 10,
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                  padding: 20,
                  background: 'rgba(0, 0, 0, 0.5)',
                  borderTopRightRadius: 40,
                  borderBottomLeftRadius: 40,
                }}
              >
                A Irê it encontra as melhores viagens para você com uma taxa
                justa. Conheça nosso app para motoristas, entregadores e
                mototaxistas
              </p>
            </EnterXDiv>

            <EnterDiv
              animateDrivers={animateDrivers}
              style={{display: 'flex', marginTop: 40}}
            >
              <Button
                style={{
                  marginRight: 10,
                  textTransform: 'none',
                  width: 180,
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginLeft: 30,
                }}
                sx={{
                  color: '#06448E',
                  bgcolor: '#FFFFFF',
                  ':hover': {
                    bgcolor: '#06448E',
                    color: '#FFFFFF',
                  },
                }}
              >
                <Android />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 20,
                    }}
                  >
                    Play Store
                  </p>
                </div>
              </Button>
              <Button
                style={{
                  marginRight: 10,
                  textTransform: 'none',
                  width: 180,
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginLeft: 20,
                }}
                sx={{
                  color: '#06448E',
                  bgcolor: '#FFFFFF',
                  ':hover': {
                    bgcolor: '#06448E',
                    color: '#FFFFFF',
                  },
                }}
              >
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Mais
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 20,
                    }}
                  >
                    Informações
                  </p>
                </div>
                <ArrowRight />
              </Button>
            </EnterDiv>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cadaster;
