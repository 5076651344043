import {ArrowForward} from '@mui/icons-material';
import {Button} from '@mui/material';
import React from 'react';
import {useMediaQuery} from 'react-responsive';
import styled, {keyframes} from 'styled-components';

const fadeInX = keyframes`
  0% {
    transform : translateX(-10em);
    opacity: 0;
  }
  50% {
    transform : translateX(-10em);
    opacity: 0;
  }
  100% {
    transform : translateX(0em);
    opacity: 1;
  }
`;

const EnterXDiv = styled.div`
  animation-fill-mode: forwards;
  animation: 1000ms ${fadeInX} linear;
`;

const Store = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  return (
    <div>
      {isVerySmall || isSmall ? (
        <div
          style={{
            background: 'linear-gradient(#FFFFFF, #E4E4E4)',
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
            paddingBottom: 60,
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 60,
              justifyContent: 'center',
              marginLeft: 10,
              marginRight: 10,
              width: '100%',
            }}
          >
            <p
              style={{
                fontSize: 36,
                width: '100%',
                marginTop: 10,
                fontWeight: 'bold',
                color: '#FF6600',
                marginBottom: 10,
                textAlign: 'center',
              }}
            >
              Seu estabelecimento na Irê it.
            </p>
            <EnterXDiv>
              <p
                style={{
                  fontSize: 24,
                  width: '90%',
                  marginTop: 30,
                  fontWeight: 'bold',
                  color: '#06448e',
                  marginLeft: 10,
                }}
              >
                Cadastra-se em nossa plataforma, aproveite a facilidade do nosso
                painel e a logística Irê it.
              </p>
            </EnterXDiv>
            <Button
              style={{
                textTransform: 'none',
                width: 'auto',
                marginLeft: 20,
                marginRight: 20,
                height: 60,
                fontWeight: 'normal',
                alignItems: 'center',
                marginTop: 40,
              }}
              sx={{
                color: '#FFFFFF',
                bgcolor: '#FF6600',
                ':hover': {
                  bgcolor: '#06448E',
                  color: '#FFFFFF',
                },
              }}
            >
              <div
                style={{marginRight: 20, display: 'flex', alignItems: 'center'}}
              >
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    fontSize: 20,
                  }}
                >
                  Cadastrar
                </p>
              </div>
              <ArrowForward />
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            background: 'linear-gradient(#FFFFFF, #E4E4E4)',
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
            paddingBottom: 60,
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p
              style={{
                fontSize: 48,
                width: '40%',
                marginLeft: 30,
                marginTop: 30,
                fontWeight: 'bold',
                color: '#FF6600',
              }}
            >
              Seu estabelecimento na Irê it.
            </p>
            <EnterXDiv>
              <p
                style={{
                  fontSize: 28,
                  width: '50%',
                  marginLeft: 30,
                  marginTop: 20,
                  fontWeight: 'bold',
                  color: '#06448e',
                }}
              >
                Cadastra-se em nossa plataforma, aproveite a facilidade do nosso
                painel e a logística Irê it.
              </p>
            </EnterXDiv>
            <Button
              style={{
                textTransform: 'none',
                width: '20%',
                marginLeft: 20,
                marginRight: 20,
                height: 60,
                fontWeight: 'normal',
                alignItems: 'center',
                marginTop: 40,
              }}
              sx={{
                color: '#FFFFFF',
                bgcolor: '#FF6600',
                ':hover': {
                  bgcolor: '#06448E',
                  color: '#FFFFFF',
                },
              }}
            >
              <div
                style={{marginRight: 20, display: 'flex', alignItems: 'center'}}
              >
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    fontSize: 20,
                  }}
                >
                  Cadastrar
                </p>
              </div>
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Store;
