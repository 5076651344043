import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import logo from '../Assets/logo.png';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Collapse,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import {ArrowDownward, ArrowForward, ArrowUpward} from '@mui/icons-material';
import {Box} from '@mui/system';
import AppsIcon from '@mui/icons-material/Apps';
import {Link} from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const Tab = ({setToggleBar}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [openProdutos, setOpenProdutos] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isVerySmall || isSmall ? (
        <div
          style={{background: '#06448e', width: '100%', overflowX: 'hidden'}}
        >
          <div
            style={{
              background: '#06448e',
              height: '60px',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              top: 0,
              width: '100%',
            }}
            className="btn-toggle"
          >
            <img
              style={{width: 'auto', height: '40px', padding: 5}}
              src={logo}
            />
            <CloseIcon
              style={{marginRight: 20, color: '#ffffff'}}
              onClick={() => setToggleBar(false)}
            />
          </div>
          <Divider style={{background: '#ff6600'}} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              style={{
                color: '#ffffff',
                paddingRight: 10,
                paddingLeft: 10,
                justifyContent: 'flex-start',
              }}
              variant="text"
              component={Link}
              to="/"
            >
              Inicio
            </Button>
            <Button
              style={{
                color: '#ffffff',
                paddingRight: 10,
                paddingLeft: 10,
                justifyContent: 'flex-start',
              }}
              variant="text"
            >
              Sobre
            </Button>
            <Button
              style={{
                color: '#ffffff',
                paddingRight: 10,
                paddingLeft: 10,
                justifyContent: 'flex-start',
              }}
              variant="text"
              component={Link}
              to="/ajuda"
            >
              Ajuda
            </Button>
            <div style={{width: '100%'}}>
              {openProdutos ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginRight: 10,
                  }}
                  onClick={() => setOpenProdutos(false)}
                >
                  <Button
                    style={{
                      color: '#ffffff',
                      paddingRight: 10,
                      paddingLeft: 10,
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                    variant="text"
                  >
                    Produtos
                  </Button>
                  <ArrowUpward style={{color: '#ffffff'}} />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginRight: 10,
                  }}
                  onClick={() => setOpenProdutos(true)}
                >
                  <Button
                    style={{
                      color: '#ffffff',
                      paddingRight: 10,
                      paddingLeft: 10,
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                    variant="text"
                  >
                    Produtos
                  </Button>
                  <ArrowDownward style={{color: '#ffffff'}} />
                </div>
              )}
            </div>

            <Collapse in={openProdutos} unmountOnExit>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  background: '#054a9d',
                }}
              >
                <Button
                  style={{
                    color: '#ffffff',
                    paddingRight: 10,
                    paddingLeft: 10,
                    justifyContent: 'flex-start',
                    marginLeft: 10,
                  }}
                  variant="text"
                  onClick={() => setOpenProdutos(true)}
                >
                  • Viagens
                </Button>
                <Button
                  style={{
                    color: '#ffffff',
                    paddingRight: 10,
                    paddingLeft: 10,
                    justifyContent: 'flex-start',
                    marginLeft: 10,
                  }}
                  variant="text"
                  onClick={() => setOpenProdutos(true)}
                >
                  • Delivery
                </Button>
                <Button
                  style={{
                    color: '#ffffff',
                    paddingRight: 10,
                    paddingLeft: 10,
                    justifyContent: 'flex-start',
                    marginLeft: 10,
                  }}
                  variant="text"
                  onClick={() => setOpenProdutos(true)}
                >
                  • Entregas
                </Button>
                <Button
                  style={{
                    color: '#ffffff',
                    paddingRight: 10,
                    paddingLeft: 10,
                    justifyContent: 'flex-start',
                    marginLeft: 10,
                  }}
                  variant="text"
                  onClick={() => setOpenProdutos(true)}
                >
                  • Motoristas
                </Button>
                <Button
                  style={{
                    color: '#ffffff',
                    paddingRight: 10,
                    paddingLeft: 10,
                    justifyContent: 'flex-start',
                    marginLeft: 10,
                  }}
                  variant="text"
                  onClick={() => setOpenProdutos(true)}
                >
                  • Mototaxistas
                </Button>
                <Button
                  style={{
                    color: '#ffffff',
                    paddingRight: 10,
                    paddingLeft: 10,
                    justifyContent: 'flex-start',
                    marginLeft: 10,
                  }}
                  variant="text"
                  onClick={() => setOpenProdutos(true)}
                >
                  • Entregadores
                </Button>
                <Button
                  style={{
                    color: '#ffffff',
                    paddingRight: 10,
                    paddingLeft: 10,
                    justifyContent: 'flex-start',
                    marginLeft: 10,
                  }}
                  variant="text"
                  onClick={() => setOpenProdutos(true)}
                >
                  • Lojas
                </Button>
              </div>
            </Collapse>
          </div>
        </div>
      ) : (
        <div style={{width: '100%'}}>
          <Box
            boxShadow={3}
            style={{
              backgroundColor: '#06448e',
              flex: 1,
              height: 60,
              position: 'fixed',
              top: 0,
              zIndex: 5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{width: 'auto', display: 'flex'}}>
              <Button
                component={Link}
                to="/"
                style={{
                  width: 95,
                  height: 47,
                  marginLeft: 12,
                  backgroundImage: `url(${logo})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              />
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  sx={{
                    color: '#ffffff',
                    ':hover': {
                      bgcolor: '#06448e',
                      color: '#ff6600',
                    },
                  }}
                  variant="text"
                  component={Link}
                  to="/"
                >
                  Inicio
                </Button>
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  variant="text"
                  sx={{
                    color: '#ffffff',
                    ':hover': {
                      bgcolor: '#06448e',
                      color: '#ff6600',
                    },
                  }}
                >
                  Sobre
                </Button>
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  variant="text"
                  sx={{
                    color: '#ffffff',
                    ':hover': {
                      bgcolor: '#06448e',
                      color: '#ff6600',
                    },
                  }}
                  component={Link}
                  to="/ajuda"
                >
                  Ajuda
                </Button>
              </div>
            </div>

            <div>
              <Button
                onClick={handleClick}
                variant="text"
                startIcon={<AppsIcon />}
                sx={{
                  color: '#ffffff',
                  ':hover': {
                    bgcolor: '#06448e',
                    color: '#ff6600',
                  },
                }}
              >
                Produtos
              </Button>
              <Menu
                style={{marginLeft: 10, marginRight: 10}}
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemText style={{marginRight: 10}}>Viagens</ListItemText>
                  <ListItemIcon>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {' '}
                  <ListItemText style={{marginRight: 10}}>Pedidos</ListItemText>
                  <ListItemIcon>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {' '}
                  <ListItemText style={{marginRight: 10}}>
                    Entregas
                  </ListItemText>
                  <ListItemIcon>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {' '}
                  <ListItemText style={{marginRight: 10}}>
                    Motoristas
                  </ListItemText>
                  <ListItemIcon>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {' '}
                  <ListItemText style={{marginRight: 10}}>
                    Mototaxistas
                  </ListItemText>
                  <ListItemIcon>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {' '}
                  <ListItemText style={{marginRight: 10}}>
                    Entregadores
                  </ListItemText>
                  <ListItemIcon>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {' '}
                  <ListItemText style={{marginRight: 10}}>Lojas</ListItemText>
                  <ListItemIcon>
                    <ArrowForward fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

export default Tab;
