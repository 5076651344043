import {Android, Apple} from '@mui/icons-material';
import {Button} from '@mui/material';
import React, {useLayoutEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import styled, {keyframes} from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform : translateY(3em);
    opacity: 0;
  }
  50% {
    transform : translateY(3em);
    opacity: 0;
  }
  100% {
    transform : translateY(0em);
    opacity: 1;
  }
`;

const fadeInX = keyframes`
  0% {
    transform : translateX(-10em);
    opacity: 0;
  }
  50% {
    transform : translateX(-10em);
    opacity: 0;
  }
  100% {
    transform : translateX(0em);
    opacity: 1;
  }
`;

const EnterDiv = styled.div`
  animation-fill-mode: forwards;
  animation: 1000ms ${fadeIn} linear;
`;

const EnterXDiv = styled.div`
  animation-fill-mode: forwards;
  animation: 1000ms ${fadeInX} linear;
`;

const Banner = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();

  return (
    <div style={{width: width, height: 'auto'}}>
      {isVerySmall || isSmall ? (
        <div
          style={{
            background: 'linear-gradient(#06448e, #7c53e3, #b553e3)',
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: width,
              marginBottom: 60,
            }}
          >
            <p
              style={{
                fontSize: 30,
                width: 'auto',
                marginLeft: 30,
                marginTop: 40,
                fontWeight: 'bold',
                color: '#FFFFFF',
              }}
            >
              Viagens, Delivery, Mercado e Entregas em um só lugar.
            </p>
            <p
              style={{
                fontSize: 18,
                width: 'auto',
                marginLeft: 30,
                marginTop: 0,
                fontWeight: 'bold',
                color: '#FFFFFF',
              }}
            >
              Os melhores serviços para você, está em nosso app. Baixe e
              confira.
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
                width: '100%',
              }}
            >
              <Button
                style={{
                  textTransform: 'none',
                  width: '70%',
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    color: '#ffffff',
                    bgcolor: '#ff6600',
                  },
                }}
              >
                <Android />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 12,
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    Play Store
                  </p>
                </div>
              </Button>
              <Button
                style={{
                  textTransform: 'none',
                  width: '70%',
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginTop: 20,
                }}
                sx={{
                  color: '#ff6600',
                  bgcolor: '#ffffff',
                  ':hover': {
                    color: '#ff6600',
                    bgcolor: '#ffffff',
                  },
                }}
              >
                <Apple />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 12,
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                  >
                    Apple Store
                  </p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            background: 'linear-gradient(#06448e, #7c53e3, #aa3cc9)',
            width: '100%',
            height: 'auto',
            marginTop: 60,
            display: 'flex',
            paddingBottom: 60,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                fontSize: 48,
                width: '50%',
                marginLeft: 30,
                marginTop: 60,
                fontWeight: 'bold',
                color: '#FFFFFF',
              }}
            >
              Viagens, Delivery, Mercado e Entregas em um só lugar.
            </p>
            <EnterXDiv>
              <p
                style={{
                  fontSize: 28,
                  width: '50%',
                  marginLeft: 30,
                  marginTop: 40,
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                }}
              >
                Os melhores serviços para você, está em nosso app. Baixe e
                confira.
              </p>
            </EnterXDiv>

            <EnterDiv style={{display: 'flex', marginTop: 60}}>
              <Button
                style={{
                  marginRight: 10,
                  textTransform: 'none',
                  width: 180,
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginLeft: 30,
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    bgcolor: '#FFFFFF',
                    color: '#FF6600',
                  },
                }}
              >
                <Android />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 20,
                    }}
                  >
                    Play Store
                  </p>
                </div>
              </Button>
              <Button
                style={{
                  marginRight: 10,
                  textTransform: 'none',
                  width: 180,
                  height: 60,
                  fontWeight: 'normal',
                  alignItems: 'center',
                  marginLeft: 30,
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#ff6600',
                  ':hover': {
                    bgcolor: '#FFFFFF',
                    color: '#FF6600',
                  },
                }}
              >
                <Apple />
                <div style={{marginLeft: 10}}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    Disponível na
                  </p>
                  <p
                    style={{
                      marginTop: -10,
                      marginBottom: 0,
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      fontSize: 20,
                    }}
                  >
                    Apple Store
                  </p>
                </div>
              </Button>
            </EnterDiv>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
